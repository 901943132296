import React from "react";

const CurrencyFormatPrice = (props) => {
  const { currency, amount } = props;

  let formatedAmout = null;
  if (amount >= 1000 && amount < 1000000) {
    formatedAmout = "rb";
  } else if (amount >= 1000000 && amount < 1000000000) {
    formatedAmout = "jt";
  } else if (amount >= 1000000000 && amount < 1000000000000) {
    formatedAmout = "M";
  } else {
    formatedAmout = amount + " Rupiah";
  }

  return (
    <>
      {currency} {amount / 1000}
      {/* {formatedAmout} */}
    </>
  );
};

CurrencyFormatPrice.defaultProps = {
  currency: "Rp.",
};

export default CurrencyFormatPrice;
